import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Pagination, Scrollbar, A11y, EffectFade } from 'swiper/modules';

function SliderEmpresas() {
    return (
        <>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
                spaceBetween={50}
                slidesPerView={6}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
            >
                <SwiperSlide>
                    <a>
                        <img src="/1.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/2.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/3.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/4.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/5.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/6.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/7.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/8.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/9.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/10.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
            </Swiper>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
                spaceBetween={50}
                slidesPerView={6}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
            >
                <SwiperSlide>
                    <a>
                        <img src="/11.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/12.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/13.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/14.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/15.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/16.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/17.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/18.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/19.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/20.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
            </Swiper>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
                spaceBetween={50}
                slidesPerView={6}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
            >
                <SwiperSlide>
                    <a>
                        <img src="/21.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/22.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/23.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/24.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/25.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/26.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/27.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/28.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/29.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/30.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
            </Swiper>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
                spaceBetween={50}
                slidesPerView={6}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
            >
                <SwiperSlide>
                    <a>
                        <img src="/31.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/32.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/33.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/34.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/35.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/36.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/37.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/38.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/39.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/40.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
            </Swiper>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
                spaceBetween={50}
                slidesPerView={6}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
            >
                <SwiperSlide>
                    <a>
                        <img src="/41.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/42.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/43.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/44.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/45.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/46.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/47.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/48.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/49.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/50.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a>
                        <img src="/51.jpg" alt="" style={{ width: "100%" }} />
                    </a>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default SliderEmpresas
