import "./App.css";
import Views from "./router/Views";

function App() {
  return (
        <Views />
  );
}

export default App;
