import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
    // import 'swiper/swiper-bundle.css';
import { Navigation, Pagination, Scrollbar, A11y, EffectFade } from 'swiper/modules';

function SliderNotas() {
    return (
        <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
            spaceBetween={20}
            slidesPerView={5}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
        >
                <SwiperSlide className='new-swiper'>
                    <a href="https://www.camara-arequipa.org.pe/public/archivos/revistas/CCIA-revista%20mayo-2024-web.pdf" target="_blank">
                        <img src="/9.jpg" alt="" style={{ width: "100%" }} />
                        <h2 className="slider-text_notas">Lorem Ipsum</h2>
                        <p className="slider-text_notas_p">Lorem Ipsum</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.camara-arequipa.org.pe/public/archivos/revistas/CCIA-revista%20mayo-2024-web.pdf" target="_blank">
                        <img src="/2.jpg" alt="" style={{ width: "100%" }} />
                        <h2 className="slider-text_notas">Lorem Ipsum</h2>
                        <p className="slider-text_notas_p">Lorem Ipsum</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.camara-arequipa.org.pe/public/archivos/revistas/CCIA-revista%20mayo-2024-web.pdf" target="_blank">
                        <img src="/3.jpg" alt="" style={{ width: "100%" }} />
                        <h2 className="slider-text_notas">Lorem Ipsum</h2>
                        <p className="slider-text_notas_p">Lorem Ipsum</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.camara-arequipa.org.pe/public/archivos/revistas/CCIA-revista%20mayo-2024-web.pdf" target="_blank">
                        <img src="/4.jpg" alt="" style={{ width: "100%" }} />
                        <h2 className="slider-text_notas">Lorem Ipsum</h2>
                        <p className="slider-text_notas_p">Lorem Ipsum</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.camara-arequipa.org.pe/public/archivos/revistas/CCIA-revista%20mayo-2024-web.pdf" target="_blank">
                        <img src="/5.jpg" alt="" style={{ width: "100%" }} />
                        <h2 className="slider-text_notas">Lorem Ipsum</h2>
                        <p className="slider-text_notas_p">Lorem Ipsum</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.camara-arequipa.org.pe/public/archivos/revistas/CCIA-revista%20mayo-2024-web.pdf" target="_blank">
                        <img src="/6.jpg" alt="" style={{ width: "100%" }} />
                        <h2 className="slider-text_notas">Lorem Ipsum</h2>
                        <p className="slider-text_notas_p">Lorem Ipsum</p>
                    </a>
                </SwiperSlide>
        </Swiper>
    );
}

export default SliderNotas;