import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';


function Slider() {
    return (
        <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={8}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
        >
            <SwiperSlide>
                <a href="https://www.camara-arequipa.org.pe/public/archivos/revistas/CCIA-revista%20mayo-2024-web.pdf" target="_blank" >
                    <img src="/revista_40anios.jpg" alt="" style={{ width: "20vh" }} />
                    <h2 className="slider-text">Revista 40</h2>
                </a>
            </SwiperSlide>
            <SwiperSlide>
                <a href="https://www.camara-arequipa.org.pe/erpccia/public/archivos/revistas/Revista-CCIA-marzo-2024.pdf" target="_blank" >
                    <img src="/revista39.jpg" alt="" style={{ width: "20vh" }} />
                    <h2 className="slider-text">Revista 39</h2>
                </a>
            </SwiperSlide>
            <SwiperSlide>
                <a href="https://www.camara-arequipa.org.pe/erpccia/public/archivos/revistas/CCIA-2023-diciembre.pdf" target="_blank" >
                    <img src="/revista38.jpg" alt="" style={{ width: "20vh" }} />
                    <h2 className="slider-text">Revista 38</h2>
                </a>
            </SwiperSlide>
            <SwiperSlide>
                <a href="https://www.camara-arequipa.org.pe/erpccia/public/archivos/revistas/revista_setiembre_2023.pdf" target="_blank" >
                    <img src="/revista37.jpg" alt="" style={{ width: "20vh" }} />
                    <h2 className="slider-text">Revista 37</h2>
                </a>
            </SwiperSlide>
            <SwiperSlide>
                <a href="https://www.camara-arequipa.org.pe/erpccia/public/archivos/revistas/Revista_CCIA_julio_2023.pdf" target="_blank" >
                    <img src="/revista36.jpg" alt="" style={{ width: "20vh" }} />
                    <h2 className="slider-text">Revista 36</h2>
                </a>
            </SwiperSlide>
            <SwiperSlide>
                <a href="https://www.camara-arequipa.org.pe/erpccia/public/archivos/revistas/Revista_CCIA_julio_2023.pdf" target="_blank" >
                    <img src="/revista36.jpg" alt="" style={{ width: "20vh" }} />
                    <h2 className="slider-text">Revista 1</h2>
                </a>
            </SwiperSlide>
            
            
            {/* ... */}
        </Swiper>
    )
}

export default Slider
