import { Route, Routes } from "react-router-dom";
import NewNavbar from "../components/NewNavbar";
import Home from "../components/Home";
import Historia from "../components/Historia";
import Editorial from "../components/Editorial";
import Articulos from "../components/Articulos";
import Entrevistas from "../components/Entrevistas";
import Legal from "../components/Legal";
import Perfiles from "../components/Perfiles";
import Asociados from "../components/Asociados";
import HistoriaEmpresarial from "../components/HistoriaEmpresarial";
import NewFooter from "../components/Footer";
import Anuncia from "../components/Anuncia";
import InformeEconomico from "../components/InformeEconomico";
import App from "../App";

const Views = () => {
    return (
        <>
            <NewNavbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/nosotros" element={<Historia />} />
                <Route path="/editorial" element={<Editorial />} />
                <Route path="/articulos" element={<Articulos />} />
                <Route path="/entrevistas" element={<Entrevistas />} />
                <Route path="/legal" element={<Legal />} />
                <Route path="/historiaEmpresarial" element={<HistoriaEmpresarial />} />
                <Route path="/perfiles" element={<Perfiles />} />
                <Route path="/asociados" element={<Asociados />} />
                <Route path="/anuncia" element={<Anuncia />} />
                <Route path="/informe" element={<InformeEconomico />} />
            </Routes>
            <NewFooter />
        </>
    );
};

export default Views;