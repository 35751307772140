import React, { useState } from 'react';

const Popup = ({ closePopup }) => {
    const [image, setImage] = useState(null);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setImage(URL.createObjectURL(file));
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <button className="close-btn" onClick={closePopup}>X</button>
                {/* <h2>Imagen Destacada</h2> */}
                <a href="https://www.camara-arequipa.org.pe/public/archivos/revistas/CCIA-revista%20mayo-2024-web.pdf" target="_blank"><img src="/REVISTA_JUNIO_2024.jpg" alt="Popup Image" className="image-display" /></a>
            </div>
        </div>
    );
};

export default Popup;
