import React, { useState } from 'react';
import EmailSender from './EmailSender';

const Footer = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [suggestion, setSuggestion] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes agregar la lógica para enviar el correo electrónico
    const formData = {
      name: name,
      email: email,
      suggestion: suggestion
    };
    console.log(formData); // Solo para demostración, reemplaza esto con el código para enviar el correo
  };

  return (
    <>
      <footer className="footer bg-gray-200">
        <div className="container flex items-center justify-between">

          <div className="footer_space">
            <img src="/LOGO_CCIA.webp" alt="Logo Revista" className="logo_footer" />
          </div>

          <div className="center footer-columns">
            <div className="footer-column">
              <h2 className="footer-text">Dirección</h2>
              <ul>
                <li className='footer-text-small'>Quezada 104,</li>
                <li className='footer-text-small'>Yanahuara, Arequipa</li>
              </ul>
            </div>
            <div className="footer-column">
              <h2 className="footer-text">Publicidad</h2>
              <ul>
                <li className='footer-text-small'>054-380505</li>
                <li className='footer-text-small'>revistacamaraaqp@cciarequipa.org</li>
              </ul>
            </div>
            <div className="footer-column">
              <h2 className="footer-text">Nosotros</h2>
              <ul>
                <li className='footer-text-small'><a href="./nosotros" target="_blank">Nosotros</a></li>
              </ul>
            </div>
            <div className="footer-column">
              <h2 className="footer-text">Contáctenos </h2>
              <ul>
                <li className='footer-text-small'><a href="./anuncia" target="_blank">Anuncia</a></li>
              </ul>
            </div>
          </div>
        </div>
        <EmailSender />


        <hr className="border-t-2 border-gray-300" />

        <div className="text-center text-gray-600">
          <p className='footer-text-small'>© <span id="currentYear"></span> Cámara de Comercio e Industria de Arequipa® 2024 </p>
        </div>
      </footer>



    </>
  );
};

export default Footer;
