import React from 'react'
import Footer from './Footer'
import NewNavbar from './NewNavbar'

const Editorial = () => {
  return (
    <>
    <div className='class pb-[50px]'>
    <h1 className='title'>EDITORIAL</h1>
    <div className='square_section_editorial'>
      <div className='info'>
        <img src="./presidente.png" alt="" className='presidente' />
        <div className='pl-[20px]'>
          <h1>AREQUIPA, <span>EN LOS OJOS <br /> DEL MUNDO</span></h1>
          <hr className="green-line" />
          <h2>Por Julio Cáceres Arce</h2>
          <h3>Presidente de la Cámara de Comercio e Industria de Arequipa</h3>

        </div>

      </div>
      <div className="column-container">
        <div className="column">
          <p>
            Arequipa es una región privilegiada: cuenta con ingentes recursos y gente emprendedora que contribuye a la economía nacional. Desde hace años, su participación en diferentes sectores productivos, tales como exportación agrícola, minería, turismo, etc., la diferenció del resto de regiones.
          </p>
          <p>
            Las condiciones para que ese crecimiento sostenido en economía se mantenga durante el 2024 dependerán en la medida que las inversiones se desarrollen según lo programado, se destraben proyectos estancados por años y la conflictividad social no se presente, tal como ocurrió en el año 2023 y que trajo consecuencias adversas en la productividad y, por ende, en el empleo.
          </p>
          <p>
            Está en manos del Ejecutivo desplegar los esfuerzos necesarios para prever estas situaciones que en nada
          </p>
        </div>
        <div className="column">
          <div className="special-paragraph">
          <hr className="green-line" />
            <p>
              La llegada de cada una de las delegaciones es una buena ocasión para que el empresariado fortalezca las sinergias necesarias y aproveche las oportunidades de inversión y comercio, a fin de que contribuya a las exportaciones.
            </p>
            <hr className="green-line" />
          </div>
          
          <p>
          ayudan a enfrentar graves problemas que agobian a la  mayoría de peruanos, como la falta de confianza para nuevas inversiones, la inseguridad que avanza en varias ciudades (entre ellas Arequipa), la pérdida de empleos, entre otros. A pesar de ello, reconforta saber cómo ven a Arequipa, tanto a nivel nacional como internacional, 
          </p>
        </div>
        <div className="column">
          <p>
          pues ha sido sede de diversas actividades, entre ellas las empresariales, tal como sucedió el año pasado con Perumin y otras. En esta oportunidad, la Ciudad Blanca vuelve a ser anfitriona del Foro de Cooperación Económica Asia-Pacífico (APEC), constituido en 1989 y conformado por 21 economías de una de las regiones más dinámicas, y que representa el 60 % del PBI mundial y el 50 % del comercio mundial.
          </p>
          <p>
            Desde que se ingresó a este importante foro, el intercambio comercial del Perú creció 12 % en promedio por año, sumado a ello los tratados de libre comercio suscritos con potencias como Australia, Canadá, China, Corea del Sur, Estados Unidos, Japón, Singapur, Chile y Tailandia, además de acuerdos preferentes con Brunéi, Malasia, Nueva Zelanda y Vietnam.
          </p>
        </div>
      </div>
      </div>
    </div>
    </>
  )
}

export default Editorial
