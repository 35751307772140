import React, { useState } from 'react';

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div key={index} className="accordion-item">
          <div className="accordion-title" onClick={() => handleClick(index)}>
            {item.title}
            <span className={`accordion-icon ${activeIndex === index ? 'active' : ''}`}>
              {activeIndex === index ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="m12 6.586-8.707 8.707 1.414 1.414L12 9.414l7.293 7.293 1.414-1.414L12 6.586z"/></svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z"/></svg>
            )}
            </span>
          </div>
          <div className={`accordion-content ${activeIndex === index ? 'active' : ''}`}>
            {activeIndex === index && ( 
              <>
                <img src={item.image} alt={item.title} className="accordion-image" />
                <div className="accordion-text">
                  <p className="accordion-name">{item.name}</p>
                  <p className="accordion-p">{item.content}</p>
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;