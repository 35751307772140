import React, { useState } from 'react';
import { Navigate, Link, useLocation } from 'react-router-dom';

const NewNavbar = () => {
  const { pathname } = useLocation();
  const [openSearch, setOpenSearch] = useState(false);
  const [menuResp, setMenuResp] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const openSearchIcon = () => {
    setOpenSearch(true);
  };

  const closeSearchIcon = () => {
    setOpenSearch(false);
  };

  const handleSearch = async () => {
    console.log("Realizar búsqueda con:", searchTerm);
    Navigate(`/busqueda/${searchTerm}`);
  };

  return (
    <>
      <nav className='navbar'>
        <div className="container">
          <div className="left">
            <a href='/'><img src="/logo_revista2.png" className="logo" alt="Flowbite Logo" /></a>
          </div>
          <div className="center">
            <img src="/logo_revista.png" className="logo2" alt="Flowbite Logo" />
          </div>
          <div className="right">
            {openSearch ? (
              <div className="search-container">
                <input
                  type="text"
                  name="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSearch();
                    }
                  }}
                  className="input-search"
                  placeholder="Buscar..."
                />
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="white"
                  className="icon-lupa"
                  onClick={closeSearchIcon}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>
            ) : (
              <svg
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                className="icon-lupa"
                onClick={openSearchIcon}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            )}
          </div>
        </div>
      </nav>

      <nav className='sub-navbar'>
        <div className="container">
          <ul className="options">
            <li><Link to="/nosotros" className={pathname === '/nosotros' ? 'active' : ''}>Nosotros</Link></li>
            <li><Link to="/editorial" className={pathname === '/editorial' ? 'active' : ''}>Editorial</Link></li>
            <li><Link to="/articulos" className={pathname === '/articulos' ? 'active' : ''}>Artículos</Link></li>
            <li><Link to="/entrevistas" className={pathname === '/entrevistas' ? 'active' : ''}>Entrevistas</Link></li>
            <li><Link to="/legal" className={pathname === '/legal' ? 'active' : ''}>Legal</Link></li>
            <li><Link to="/historiaEmpresarial" className={pathname === '/historiaEmpresarial' ? 'active' : ''}>Historia Empresarial</Link></li>
            <li><Link to="/perfiles" className={pathname === '/perfiles' ? 'active' : ''}>Perfiles Empresariales</Link></li>
            <li><Link to="/asociados" className={pathname === '/asociados' ? 'active' : ''}>Nuestros Asociados</Link></li>
            <li><Link to="/informe" className={pathname === '/informe' ? 'active' : ''}>Informe Económico</Link></li>
            <li><Link to="/anuncia" className={pathname === '/anuncia' ? 'active' : ''}>Anuncia con Nosotros</Link></li>
          </ul>
        </div>
      </nav>
      </>
  );
};

export default NewNavbar;
