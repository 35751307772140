// import React from 'react';

// const CustomAd = ({ imageUrl, link, title, description }) => {
//   return (
//     <div style={{ 
//         // border: '1px solid #ddd', 
//         padding: '0px', margin: '0px 0' }}>
//       <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
//         <img src={imageUrl} alt={title} style={{ width: '100%' }} />
//         {/* <h3>{title}</h3>
//         <p>{description}</p> */}
//       </a>
//     </div>
//   );
// };

// export default CustomAd;
import React, { useState, useEffect } from 'react';

const CustomAd = ({ images, link, title, description, interval = 3000 }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const imageInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(imageInterval); // Clear interval on component unmount
  }, [images.length, interval]);

  return (
    <div style={{ padding: '0px', margin: '0px 0', position: 'relative' }}>
      <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
        <img src={images[currentImageIndex]} alt={title} style={{ width: '100%' }} />
      </a>
      {/* Optionally include title and description */}
      {/* <h3>{title}</h3>
      <p>{description}</p> */}
    </div>
  );
};

export default CustomAd;


