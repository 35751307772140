import React, { useState } from 'react'
import Slider from './Slider';
import SliderEmpresas from './SliderEmpresas';

const Anuncia = () => {
    const [selectedOption, setSelectedOption] = useState(null);

    const cambiarOpcion = () => {
        setSelectedOption('first');
    }
    const cambiarOpcion2 = () => {
        setSelectedOption('second');
    }
    const cambiarOpcion3 = () => {
        setSelectedOption('third');
    }
    const cambiarOpcion4 = () => {
        setSelectedOption('fourth');
    }
    return (
        <>
            <div className='class pb-[0px]'>
            <h1 className='title'>EMPRESAS QUE NOS ACOMPAÑAN...</h1>
            <div className="">
                <SliderEmpresas />
                {/* <div className='empresas'>
                    <img src="/empresas2.jpg" className="img_empresas" alt="Flowbite Logo" />
                    <img src="/empresas3.jpg" className="img_empresas" alt="Flowbite Logo" />
                </div> */}
                </div>

                <div className='empresas_seccion'>
                    <nav className='empresas_navbar'>
                        <div className="container">
                            <ul className="options">
                                <li><button
                                    onClick={cambiarOpcion}
                                    className={selectedOption === 'first' ? 'selected' : ''}
                                >¿Qué ofrecemos?</button></li>
                                <li><button
                                    onClick={cambiarOpcion2}
                                    className={selectedOption === 'second' ? 'selected' : ''}
                                >¿Qué nos diferencia de otras revistas?</button></li>
                                <li><button
                                    onClick={cambiarOpcion3}
                                    className={selectedOption === 'third' ? 'selected' : ''}
                                >¿Por qué confiar en nosotros?</button></li>
                                <li><button
                                    onClick={cambiarOpcion4}
                                    className={selectedOption === 'fourth' ? 'selected' : ''}
                                >Precios</button></li>
                            </ul>
                        </div>
                    </nav>
                </div>
                {selectedOption === 'first' && (
                    <img src="/ofrecemos.jpg" className="img_empresas" alt="Flowbite Logo" />
                )}
                {selectedOption === 'second' && (
                    <img src="/diferencia.jpg" className="img_empresas" alt="Flowbite Logo" />
                )}
                {selectedOption === 'third' && (
                    <img src="/confiar.jpg" className="img_empresas" alt="Flowbite Logo" />
                )}
                {selectedOption === 'fourth' && (
                    <>
                        <img src="/precios1.jpg" className="img_empresas" alt="Flowbite Logo" />
                        <img src="/precios2.jpg" className="img_empresas" alt="Flowbite Logo" />
                    </>
                )}
            </div>
        </>
    )
}

export default Anuncia
