import React from 'react';

const NewspaperLayout = ({ mainImage, mainTitle, mainText, column1Image, column1Text, column2Image, column2Text, rightColumnImage, rightColumnText }) => {
    return (
        <div className="columns-container-principal">
            <div className="main-news">
                <img src={mainImage} alt={mainTitle} />
                <h2>{mainTitle}</h2>
                <p>{mainText}</p>
                <div className="columns-container">
                    <div className="column">
                        <img src={column1Image} alt="Column 1" />
                        <h2>{mainTitle}</h2>
                        <p>{mainText}</p>
                    </div>
                    <div className="column">
                        <img src={column2Image} alt="Column 2" />
                        <h2>{mainTitle}</h2>
                        <p>{mainText}</p>
                    </div>
                </div>
            </div>

            <div className="right-column">
                <div className="column-right">
                    <img src={column1Image} alt="Column 1" />
                    <h2>{mainTitle}</h2>
                </div>
                <div className="column-right">
                    <img src={column2Image} alt="Column 2" />
                    <h2>{mainTitle}</h2>
                </div>
                <div className="column-right">
                    <img src={column2Image} alt="Column 2" />
                    <h2>{mainTitle}</h2>
                </div>
            </div>
        </div>
    );
};

export default NewspaperLayout;
