import React from 'react';
import NewspaperSection from './NewspaperSection';
import Accordion from './Accordion';

const Asociados = () => {
  const text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit.";
  const time = "Lectura de 5 minutos"

  const accordionItems = [
    {
      title: 'Cajón 1',
      name: 'Lorem ipsum',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      image: 'https://via.placeholder.com/150'
    },
    {
      title: 'Cajón 2',
      name: 'Lorem ipsum',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      image: 'https://via.placeholder.com/150'
    },
    {
      title: 'Cajón 3',
      name: 'Lorem ipsum',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      image: 'https://via.placeholder.com/150'
    }
  ];

  return (
    <div className="class justify-center">
      <h1 className='title'>ASOCIADOS</h1>
      <NewspaperSection
        title="Noticia Importante"
        image="https://via.placeholder.com/300"
        text={text}
        time={time}
      />
      <h1 className='title_asociados'>Asociado opina</h1>
      {/* <div className="first_column-container">
        <div className="column">
          <p className='box_asociados_space'>
            <div className='box_asociados'>
              <p className='box_asociados_inside_text'>
                <a href='/entrevistas'>
                  Lorem Ipsum
                </a>
                <p className='box_asociados_text'> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </p>
            </div>
          </p>
          <p className='box_asociados_space'>
            <div className='box_asociados'>
              <p className='box_asociados_inside_text'>
                <a href='/entrevistas'>
                  Lorem Ipsum
                </a>
                <p className='box_asociados_text'> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </p>
            </div>
          </p>
          
        </div>
        <div className="column">
          <p className='box_asociados_space'>
            <div className='box_asociados'>
              <p className='box_asociados_inside_text'>
                <a href='/entrevistas'>
                  Lorem Ipsum
                </a>
                <p className='box_asociados_text'> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </p>
            </div>
          </p>
          <p className='box_asociados_space'>
            <div className='box_asociados'>
              <p className='box_asociados_inside_text'>
                <a href='/entrevistas'>
                  Lorem Ipsum
                </a>
                <p className='box_asociados_text'> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </p>
            </div>
          </p>
        </div>
        <div className="column">
          <p className='box_asociados_space'>
            <div className='box_asociados'>
              <p className='box_asociados_inside_text'>
                <a href='/entrevistas'>
                  Lorem Ipsum
                </a>
                <p className='box_asociados_text'> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </p>
            </div>
          </p>
          <p className='box_asociados_space'>
            <div className='box_asociados'>
              <p className='box_asociados_inside_text'>
                <a href='/entrevistas'>
                  Lorem Ipsum
                </a>
                <p className='box_asociados_text'> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </p>
            </div>
          </p>
        </div>
      </div> */}
      <Accordion items={accordionItems} />

    </div>
  );
};

export default Asociados;
