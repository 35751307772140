import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

const EmailSender = () => {
    const form = useRef();
    const [messageSent, setMessageSent] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                'service_szawblf',
                'template_yj3s4on',
                form.current, {
                publicKey: 'oUoDxG0UgB2aCApHV',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setMessageSent(true);
                    form.current.reset();  // Resetea el formulario
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <div className='footer_email'>
            <h2 className='footer_email_text'>Envíanos tus sugerencias</h2>
            <form ref={form} onSubmit={sendEmail}>
                <div className="input_container">
                    <input
                        className='input_footer'
                        type="text"
                        name="user_name"
                        placeholder="Nombre"
                        required
                    />
                    <input
                        className='input_footer'
                        type="email"
                        name="user_email"
                        placeholder="Correo Electrónico"
                        required
                    />
                    <textarea
                        className='input_footer'
                        placeholder="Sugerencia"
                        name="message"
                        required
                    ></textarea>
                    <button type="submit" value="Send" className='submit_footer'>Enviar</button>
                </div>
            </form>
            {messageSent && <p className='success_message'>¡Tu mensaje ha sido enviado con éxito!</p>}
        </div>
    );
};

export default EmailSender;
